import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import useEyeDropper from "use-eye-dropper";
import { removeBackground } from "./utils/removeBackground";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

interface Color {
  hair: string[];
  skin: string[];
  lips: string[];
  eyes: string[];
}

interface Analysis {
  season: string;
  analysis: string;
  celebrities?: string[];
  hair?: {
    colors: string[];
    description: string;
  };
  eyes?: {
    colors: string[];
    description: string;
  };
  skin?: {
    tones: string[];
    description: string;
  };
  bestColors?: {
    colors: { [key: string]: string };
    value: number;
    chroma: number;
  };
  worstColors?: string[];
  recommendations?: {
    tones: string;
    clothing: {
      dresses: string[];
      tops: string[];
      pants: string[];
      accessories: string[];
    };
    colorFamilies: string;
    avoidColors: string;
    jewelry?: string[];
  };
  palettes?: {
    classic: string[];
    bold: string[];
    natural: string[];
  };
}

const App: React.FC = () => {
  const [image, setImage] = useState<string | null>(null);
  const [colors, setColors] = useState<Color>({
    hair: [],
    skin: [],
    lips: [],
    eyes: [],
  });
  const [activeColor, setActiveColor] = useState<keyof Color | null>(null);
  const [palette, setPalette] = useState<string[]>([]);
  const [analysis, setAnalysis] = useState<Analysis | null>(null);
  const { open, isSupported } = useEyeDropper();
  const [isLoading, setIsLoading] = useState(false);
  const [isRemovingBackground, setIsRemovingBackground] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState<string>("#FFFFFF");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [processedImage, setProcessedImage] = useState<string | null>(null);
  const colorSectionHeight = 140;

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target?.result as string);
        setProcessedImage(null); // Reset processed image
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (image && !processedImage && !isRemovingBackground) {
      setIsRemovingBackground(true);
      removeBackground(image)
        .then(setProcessedImage)
        .catch(console.error)
        .finally(() => setIsRemovingBackground(false));
    }
  }, [image, processedImage, isRemovingBackground]);

  const handleColorPick = async (colorType: keyof Color) => {
    if (!isSupported()) {
      alert("Eye dropper is not supported in this browser");
      return;
    }

    try {
      const color = await open();
      setColors((prevColors) => ({
        ...prevColors,
        [colorType]: [...prevColors[colorType], color.sRGBHex],
      }));
    } catch (e) {
      console.error("Error picking color:", e);
    }
  };

  const removeColor = (colorType: keyof Color, index: number) => {
    setColors((prevColors) => ({
      ...prevColors,
      [colorType]: prevColors[colorType].filter((_, i) => i !== index),
    }));
  };

  const areAllColorsSet = () => {
    return Object.values(colors).every((colorArray) => colorArray.length > 0);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_CHATGPT_API_ENDPOINT || "",
        {
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content:
                "You are a renowned South Korean fashion designer and color analyst, known for your expertise in personal color analysis and creating flattering wardrobes based on individual coloring.",
            },
            {
              role: "user",
              content: `As a color analysis expert, please analyze the following colors and provide a detailed personal color analysis using the 12-season system:
                Hair: ${colors.hair.join(", ")}
                Skin: ${colors.skin.join(", ")}
                Lips: ${colors.lips.join(", ")}
                Eyes: ${colors.eyes.join(", ")}

                Follow these steps to determine the color season:

                1. Analyze the undertone:
                  - Warm: If hair, skin, and eyes have golden/peachy undertones
                  - Cool: If hair, skin, and eyes have ashy/blue undertones
                  - Neutral: If there's a mix or it's hard to determine

                2. Determine overall contrast:
                  - High: Significant difference between hair, skin, and eye colors
                  - Low: Little difference between hair, skin, and eye colors

                3. Assess color intensity:
                  - Clear: Colors appear bright and vivid
                  - Muted: Colors appear soft and muted

                4. Evaluate depth:
                  - Light: Hair is lighter than medium brown
                  - Deep: Hair is darker than medium brown

                5. Based on the above, categorize into one of the 12 seasons:
                  - Light Spring, Clear Spring, Warm Spring
                  - Light Summer, Cool Summer, Soft Summer
                  - Soft Autumn, Warm Autumn, Deep Autumn
                  - Cool Winter, Clear Winter, Deep Winter

                Provide a comprehensive color profile including:

                1. The determined color season (one of the 12) and a detailed explanation.
                2. List 4-6 celebrity examples with similar coloring.
                3. Detailed descriptions for hair, eyes, and skin tones, including color variations and characteristics.
                4. Suggest the best colors, including a grid of 16 color swatches with labels, and indicate the general value (light to deep) and chroma (soft to clear) of these colors.
                5. List the worst colors to avoid, with 6-8 examples.
                6. Recommend specific clothing colors for different items (e.g., dresses, tops, pants, accessories).
                7. Explain which color families would be most flattering and why.
                8. Suggest three different color palettes for their wardrobe: classic, bold, and natural.

                Please format your response as a JSON object with the following structure:
                {
                  "season": "Determined color season (one of the 12)",
                  "analysis": "Detailed analysis of their coloring and why it fits the determined season",
                  "celebrities": ["Celebrity1", "Celebrity2", "Celebrity3", "Celebrity4"],
                  "hair": {
                    "colors": ["Color1", "Color2", "Color3", "Color4"],
                    "description": "Detailed description of hair characteristics"
                  },
                  "eyes": {
                    "colors": ["Color1", "Color2", "Color3"],
                    "description": "Detailed description of eye characteristics"
                  },
                  "skin": {
                    "tones": ["Tone1", "Tone2", "Tone3", "Tone4"],
                    "description": "Detailed description of skin characteristics"
                  },
                  "bestColors": {
                    "colors": {
                      "Color1": "#HEX1",
                      "Color2": "#HEX2",
                      // ... up to 16 colors
                    },
                    "value": 0.7, // 0 to 1, where 0 is light and 1 is deep
                    "chroma": 0.6 // 0 to 1, where 0 is soft and 1 is clear
                  },
                  "worstColors": ["#HEX1", "#HEX2", "#HEX3", "#HEX4", "#HEX5", "#HEX6"],
                  "recommendations": {
                    "tones": "Warm or cool, with explanation",
                    "clothing": {
                      "dresses": ["Color1", "Color2", "Color3"],
                      "tops": ["Color1", "Color2", "Color3"],
                      "pants": ["Color1", "Color2", "Color3"],
                      "accessories": ["Color1", "Color2", "Color3"],
                      "jewelry": ["Color1", "Color2", "Color3"]
                    },
                    "colorFamilies": "Explanation of flattering color families",
                    "avoidColors": "Colors to avoid and why"
                  },
                  "palettes": {
                    "classic": ["#HEX1", "#HEX2", "#HEX3", "#HEX4", "#HEX5", "#HEX6"],
                    "bold": ["#HEX1", "#HEX2", "#HEX3", "#HEX4", "#HEX5", "#HEX6"],
                    "natural": ["#HEX1", "#HEX2", "#HEX3", "#HEX4", "#HEX5", "#HEX6"]
                  }
                }

                Ensure all color values are provided as hex codes where applicable.`,
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CHATGPT_API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );

      const content = response.data.choices[0].message.content;

      try {
        // Remove the code block markers if they exist
        const jsonString = content.replace(/```json\n|\n```/g, "");
        const result = JSON.parse(jsonString);
        console.log("Parsed result:", result);
        setAnalysis(result);
      } catch (parseError) {
        console.error("Error parsing JSON:", parseError);
        console.log("Raw content:", content);
      }
    } catch (error: unknown) {
      console.error("Error generating palette:", error);
      if (error instanceof Error) {
        console.error("Error message:", error.message);
      }
      if (axios.isAxiosError(error) && error.response) {
        console.error("Error response:", error.response.data);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="flex h-screen bg-pink-100"
      style={{
        backgroundImage: "url(./shapes.svg)",
      }}>
      {/* Left Panel */}
      <div className="w-1/2 p-4 flex flex-col">
        <h1 className="text-2xl font-bold mb-4 text-center">
          Color Palette Generator
        </h1>
        {/* Step 1: Upload Image */}
        <div
          className="flex-grow relative overflow-hidden"
          style={{
            backgroundColor,
            height: `calc(100% - ${colorSectionHeight}px - 2rem)`, // Subtract color section height and some padding
          }}>
          {image && (
            <>
              <img
                src={processedImage || image}
                alt="Uploaded selfie"
                className={`w-full h-full object-contain ${
                  isRemovingBackground ? "blur-sm" : ""
                }`}
              />
              {isRemovingBackground && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                  <div className="text-white text-center">
                    <svg
                      className="animate-spin h-10 w-10 mb-2 mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24">
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <p>Removing background...</p>
                  </div>
                </div>
              )}
            </>
          )}
          {!image && (
            <div className="absolute inset-0 flex items-center justify-center">
              <label
                htmlFor="fileInput"
                className="cursor-pointer bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
                data-tooltip-id="step1-tooltip">
                Upload Image
              </label>
              <input
                id="fileInput"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                ref={fileInputRef}
                className="hidden"
              />
            </div>
          )}
        </div>

        {/* Step 2: Color Selection */}
        <div
          className="mt-4 grid grid-cols-2 gap-2"
          style={{ height: `${colorSectionHeight}px` }}>
          {Object.entries(colors).map(([key, value]) => (
            <div key={key} className="flex flex-col items-center">
              <button
                onClick={() => handleColorPick(key as keyof Color)}
                className="w-full px-2 py-1 text-sm text-white rounded transition duration-300 bg-blue-500 hover:bg-blue-600"
                data-tooltip-id="step2-tooltip">
                Add {key} color
              </button>
              <div className="mt-1 flex flex-wrap gap-1">
                {value.map((color: string, index: number) => (
                  <div key={index} className="relative">
                    <div
                      className="w-6 h-6 rounded-full border border-gray-300"
                      style={{ backgroundColor: color }}
                    />
                    <button
                      onClick={() => removeColor(key as keyof Color, index)}
                      className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full w-3 h-3 flex items-center justify-center text-xs">
                      ×
                    </button>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* Step 3: Generate Palette */}
        <button
          onClick={handleSubmit}
          disabled={isLoading || !areAllColorsSet()}
          className={`mt-4 w-full px-4 py-2 text-white rounded transition duration-300 ${
            isLoading || !areAllColorsSet()
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-green-500 hover:bg-green-600"
          }`}
          data-tooltip-id="step3-tooltip">
          {isLoading ? "Generating..." : "Generate Palette"}
        </button>
      </div>

      {/* Right Panel */}
      <div className="w-1/2 p-4 overflow-y-auto">
        {analysis ? (
          <div className="bg-white rounded shadow p-4">
            <h2 className="text-xl font-bold mb-2">Personal Color Analysis</h2>
            {analysis.season && (
              <p className="mb-1">
                <strong>Season:</strong> {analysis.season}
              </p>
            )}
            {analysis.analysis && (
              <p className="mb-2">
                <strong>Analysis:</strong> {analysis.analysis}
              </p>
            )}

            {analysis.recommendations?.jewelry && (
              <>
                <h3 className="text-lg font-bold mb-1">
                  Recommended Jewelry Colors
                </h3>
                <ul className="list-disc list-inside mb-2">
                  {analysis.recommendations.jewelry.map((color, index) => (
                    <li key={index}>{color}</li>
                  ))}
                </ul>
              </>
            )}

            {analysis.celebrities && analysis.celebrities.length > 0 && (
              <>
                <h3 className="text-xl font-bold mb-2">Celebrity Examples</h3>
                <ul className="list-disc list-inside mb-4">
                  {analysis.celebrities.map((celebrity, index) => (
                    <li key={index}>{celebrity}</li>
                  ))}
                </ul>
              </>
            )}

            {analysis.hair && (
              <>
                <h3 className="text-xl font-bold mb-2">Hair</h3>
                {analysis.hair.description && (
                  <p className="mb-2">{analysis.hair.description}</p>
                )}
                {analysis.hair.colors && analysis.hair.colors.length > 0 && (
                  <div className="flex flex-wrap gap-2 mb-4">
                    {analysis.hair.colors.map((color, index) => (
                      <div
                        key={index}
                        className="w-8 h-8 rounded"
                        style={{ backgroundColor: color }}></div>
                    ))}
                  </div>
                )}
              </>
            )}

            {analysis.eyes && (
              <>
                <h3 className="text-xl font-bold mb-2">Eyes</h3>
                {analysis.eyes.description && (
                  <p className="mb-2">{analysis.eyes.description}</p>
                )}
                {analysis.eyes.colors && analysis.eyes.colors.length > 0 && (
                  <div className="flex flex-wrap gap-2 mb-4">
                    {analysis.eyes.colors.map((color, index) => (
                      <div
                        key={index}
                        className="w-8 h-8 rounded-full"
                        style={{ backgroundColor: color }}></div>
                    ))}
                  </div>
                )}
              </>
            )}

            {analysis.skin && (
              <>
                <h3 className="text-xl font-bold mb-2">Skin</h3>
                {analysis.skin.description && (
                  <p className="mb-2">{analysis.skin.description}</p>
                )}
                {analysis.skin.tones && analysis.skin.tones.length > 0 && (
                  <div className="flex flex-wrap gap-2 mb-4">
                    {analysis.skin.tones.map((tone, index) => (
                      <div
                        key={index}
                        className="w-8 h-8 rounded"
                        style={{ backgroundColor: tone }}></div>
                    ))}
                  </div>
                )}
              </>
            )}

            {analysis.bestColors && analysis.bestColors.colors && (
              <>
                <h3 className="text-lg font-bold mb-1">Best Colors</h3>
                <div className="grid grid-cols-4 gap-1 mb-2">
                  {Object.entries(analysis.bestColors.colors).map(
                    ([name, color], index) => (
                      <button
                        key={index}
                        className="flex flex-col items-center p-1 rounded transition-all duration-300 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        onClick={() => setBackgroundColor(color)}
                        data-tooltip-id="color-tooltip"
                        data-tooltip-content={`Click to see ${name} as background`}>
                        <div
                          className="w-8 h-8 rounded-full border border-gray-300"
                          style={{ backgroundColor: color }}></div>
                        <span className="text-xs mt-1">{name}</span>
                      </button>
                    )
                  )}
                </div>
              </>
            )}

            {analysis.worstColors && analysis.worstColors.length > 0 && (
              <>
                <h3 className="text-xl font-bold mb-2">Worst Colors</h3>
                <div className="flex flex-wrap gap-2 mb-4">
                  {analysis.worstColors.map((color, index) => (
                    <button
                      key={index}
                      className="w-10 h-10 rounded-full border-2 border-gray-300 transition-all duration-300 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      style={{ backgroundColor: color }}
                      onClick={() => setBackgroundColor(color)}></button>
                  ))}
                </div>
              </>
            )}

            {analysis.palettes && (
              <>
                <h3 className="text-xl font-bold mb-2">Color Palettes</h3>
                <div className="space-y-4">
                  {(["classic", "bold", "natural"] as const).map(
                    (paletteType) => {
                      const palette = analysis.palettes?.[paletteType];
                      return palette && palette.length > 0 ? (
                        <div key={paletteType}>
                          <h4 className="text-lg font-bold capitalize mb-2">
                            {paletteType} Palette
                          </h4>
                          <div className="flex flex-wrap gap-2">
                            {palette.map((color: string, index: number) => (
                              <button
                                key={index}
                                style={{ backgroundColor: color }}
                                className="w-10 h-10 rounded-full border-2 border-gray-300 transition-all duration-300 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                onClick={() =>
                                  setBackgroundColor(color)
                                }></button>
                            ))}
                          </div>
                        </div>
                      ) : null;
                    }
                  )}
                </div>
              </>
            )}
          </div>
        ) : (
          <p className="text-center text-gray-500">
            No analysis available. Generate a palette to see results.
          </p>
        )}
      </div>
      {/* Tooltips */}
      <Tooltip id="step1-tooltip" place="top">
        Step 1: Upload an image of yourself
      </Tooltip>
      <Tooltip id="step2-tooltip" place="top">
        Step 2: Use the eyedropper to select multiple colors for each section
      </Tooltip>
      <Tooltip id="step3-tooltip" place="top">
        Step 3: Generate your color analysis
      </Tooltip>
      <Tooltip id="color-tooltip" place="top" />
    </div>
  );
};

export default App;
