import axios from "axios";

export const removeBackground = async (
  imageDataUrl: string
): Promise<string> => {
  // Convert data URL to Blob
  const response = await fetch(imageDataUrl);
  const blob = await response.blob();

  const formData = new FormData();
  formData.append("image_file", blob, "image.png");

  try {
    const response = await axios.post(
      "https://clipdrop-api.co/remove-background/v1",
      formData,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_CLIPDROP_API_KEY,
          "Content-Type": "multipart/form-data",
        },
        responseType: "arraybuffer",
      }
    );

    const base64 = btoa(
      new Uint8Array(response.data).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    );
    return `data:image/png;base64,${base64}`;
  } catch (error) {
    console.error("Error removing background:", error);
    throw error;
  }
};
